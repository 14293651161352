body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.coming-soon{
    max-width: 800px;
    width: 100%;
}

.ReactModal__Content{
  padding: 0 !important;
    background: transparent !important;
    border: none !important;
}

.close-modal{
  position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 23px;
    font-family: 'GilroyBold';
    background: transparent;
    border: none;
    cursor: pointer;
}

.ReactModal__Overlay{
  z-index: 9999999 !important;
}

@media screen and (max-width: 768px){
  .ReactModal__Content{
    max-width: 80%;
    display: block;
    margin: 0 auto;
  }
}