.btn-white {
    margin-right: 10px;
}

.title-main{
    text-decoration: none;
    color: #fff;
}

@media screen and (max-width: 486px){
    .header-button{
        padding: 2px 9px !important;
        font-size: 17px !important;
    }

    .logo-header{
        width: 126px;
    }
    .logo-header a{
        width: 100% !important;
        height: 58px !important;
    }
}
@media screen and (max-width: 425px){
    .header-button{
        padding: 2px 9px !important;
        font-size: 17px !important;
    }

    .logo-header{
        width: 126px;
    }
    .logo-header a{
        width: 100% !important;
        height: 84px !important;
    }

}
@media screen and (max-width: 428px){
    .logo-header a {
        height: 63px !important;
    }

    .logo-header{
        width: 80px;
    }
    .header-button {
        font-size: 14px !important;
    }

    .men-logo{
        padding-top: 4px !important;
    }
}

@media screen and (max-width: 375px){
    .logo-header a {
        height: 63px !important;
    }

    .logo-header{
        width: 80px;
    }
    .header-button {
        font-size: 14px !important;
    }

    .men-logo{
        padding-top: 4px !important;
    }
}